@font-face {
    font-family: 'Sputnik Outline';
    src: url('../public/fonts/Sputnik Outline.otf') format('opentype');
}
@font-face {
    font-family: 'Sputnik Regular';
    src: url('../public/fonts/Sputnik Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Sputnik Rough';
    src: url('../public/fonts/Sputnik Rough.otf') format('opentype');
}
@font-face {
    font-family: 'Sputnik InLine';
    src: url('../public/fonts/Sputnik InLine.otf') format('opentype');
}
@font-face {
    font-family: 'Adrasta-Regular';
    src: url('../public/fonts/Adrasta-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Wraith';
    src: url('../public/fonts/Wraith.otf') format('opentype');
}
@font-face {
    font-family: 'Calamity Regular';
    src: url('../public/fonts/Calamity Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Grind Demolished';
    src: url('../public/fonts/Grind Demolished.otf') format('opentype');
}
/*Useful*/
@font-face {
    font-family: 'Bios Regular';
    src: url('../public/fonts/Bios Regular.otf') format('opentype');
}
/*Looks good*/
@font-face {
    font-family: 'Bios Bold';
    src: url('../public/fonts/Bios Bold.otf') format('opentype');
}
/*Useful - Glitch Text*/
@font-face {
    font-family: 'Avalon';
    src: url('../public/fonts/Avalon.otf') format('opentype');
}
@font-face {
    font-family: 'Starforge Regular';
    src: url('../public/fonts/Starforge Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Atecha';
    src: url('../public/fonts/Atecha.otf') format('opentype');
}
@font-face {
    font-family: 'Astronomus-Regular';
    src: url('../public/fonts/Astronomus-Regular.otf') format('opentype');
}
/*Useful*/
@font-face {
    font-family: 'Astronomus-Italic';
    src: url('../public/fonts/Astronomus-Italic.otf') format('opentype');
}
@font-face {
    font-family: 'Polaris';
    src: url('../public/fonts/Polaris.otf') format('opentype');
}
@font-face {
    font-family: 'Osiris';
    src: url('../public/fonts/Osiris.otf') format('opentype');
}

.arwes-card__content-bg {
    z-index: -1;
}

.explosion-icon-mask-image {
    mask-image: url("https://game.local/main/images/ExplosionIcon3.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

.missile-icon-mask-image {
    mask-image: url("https://game.local/main/images/MissileIcon.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

.cannon-icon-mask-image {
    mask-image: url("https://game.local/main/images/CannonIcon3.svg");
    mask-size: 15px;
    mask-repeat: no-repeat;
    mask-position: center;
}

.bullet-icon-mask-image {
    mask-image: url("https://game.local/main/images/BulletsIcon2.svg");
    mask-size: 10px;
    mask-repeat: no-repeat;
    mask-position: center;
}

.sniper-icon-mask-image {
    mask-image: url("https://game.local/main/images/SniperIcon2.svg");
    mask-size: 13px;
    mask-repeat: no-repeat;
    mask-position: center;
}

.cross-icon-mask-image {
    mask-image: url("https://game.local/main/images/Cross2.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

.stealth-icon-mask-image {
    mask-image: url("https://game.local/main/images/Stealth.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

.zenith-icon-mask-image {
    mask-image: url("https://game.local/main/images/Zenith.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

.mechanic-icon-mask-image {
    mask-image: url("https://game.local/main/images/Mechanic2.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

.engineer-icon-mask-image {
    mask-image: url("https://game.local/main/images/Hardhat.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}
